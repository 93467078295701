import React from 'react';
import ReactDOM from 'react-dom/client';
// import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
// componants
// import App from './app'; // not used
import PageHome from './pages/page-home';
import PageImages from './pages/page-images';
import PageFilms from './pages/page-films';
import PagePreWeddings from './pages/page-preweddings';
import PageContact from './pages/page-contact';
import Page404 from './pages/page-404';
// css
import './index.scss';

const router = createBrowserRouter([
     {
       path: "/",
       element: <PageHome />,
     }, 
     {
       path: "/images",
       element: <PageImages />,
     },
     {
       path: "/films",
       element: <PageFilms />,
     },
     {
       path: "/pre-weddings",
       element: <PagePreWeddings />,
     },
     {
       path: "/contact",
       element: <PageContact />,
     },
     {
       path: "/*",
       element: <Page404 />,
     }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
     <React.StrictMode>
        <RouterProvider
            router={router}
        />
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
