import React from 'react';
import { Link, useLocation } from "react-router-dom";

const headerData = [
	{
		title: 'Home',
		slug: '/'
	},
	{
		title: 'Images',
		slug: '/images'
	},
	{
		title: 'Films',
		slug: '/films'
	},
	{
		title: 'Pre Weddings',
		slug: '/pre-weddings'
	},
	{
		title: 'Contact',
		slug: '/contact'
	}
]

const usePathname = () => {
	const location = useLocation();
	return location.pathname;
}

const Header = (props) => {
	const pageSlug = usePathname().replace(/\//g, "");
	console.log('pageSlug: ', pageSlug);

	return (
		<div className="mrp-header">
			<nav className="navbar navbar-expand-lg bg-dark navbar-dark fixed-top">
				<div className="container">
					<Link to="/" className="navbar-brand">
						<img src="/images/mrp-logo.png" alt="mrp-logo" />
					</Link>

					<button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" aria-label="Toggle navigation">
						<span className="navbar-toggler-icon"></span>
					</button>

					<div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
						<div className="offcanvas-header">
							<h5 className="offcanvas-title" id="offcanvasNavbarLabel">Mountain Roots Production</h5>
							<button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
						</div>
						<div className="offcanvas-body">
							<ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
								{headerData.map((item, index)=>{
									return (
										<li key={index} className={`nav-item ${pageSlug === (item?.slug).replace(/\//g, "") ? 'active' : ''}`} data-bs-dismiss="offcanvas">
										 	<Link to={item?.slug} className="nav-link">
										 		{item?.title}
										 	</Link>	
										</li>
									)
								})}
							</ul>
						</div>
					</div>
				</div>
			</nav>
		</div>
	);
}

export default Header
