import React from "react";
import Header from '../components/header';
import Footer from '../components/footer';
import LazyLoad from 'react-lazyload';
// css
import '../css/contact.scss';

const teamData = [
	{
		img: "/images/team/team-tanmay.jpg",
		title: "Tanmay Rawat",
		position: "Director"	
	},
	{
		img: "/images/team/babita.jpg",
		title: "Babita Rawat",
		position: "Makeup Artist"	
	},
	{
		img: "/images/team/team-pawan.jpg",
		title: "Pawan",
		position: "Cinematographer"	
	},
	{
		img: "/images/team/team-abhinav.jpg",
		title: "Abhinav",
		position: "Candidate Photographer"	
	},
	{
		img: "/images/team/team-gulshan.jpg",
		title: "Gulshan",
		position: "Traditional Photographer"	
	},
	{
		img: "/images/team/team-amit.jpg",
		title: "Amit",
		position: "Traditional Videographer"	
	},
	{
		img: "/images/team/team-jawed.jpg",
		title: "Jawed",
		position: "Cinematographer"	
	},
	{
		img: "/images/team/team-vaibhav.jpg",
		title: "Vaibhav",
		position: "Creative Head"	
	},
	{
		img: "/images/team/team-akshay.jpg",
		title: "Akshay",
		position: "Post Production Head"	
	},
	{
		img: "/images/team/amit-rawat.jpg",
		title: "Amit Rawat",
		position: "Finance Head"	
	}
]

const PageContact = (props) => {
	return (
		<>
			<Header />
				<div className="page-contact">
					<div className="container">	
						<h1 className="main-heading">Contact Us</h1>

						<div className="contact-desc">
							<p>Mountain Roots Production team of experienced professionals believe in the power of storytelling. Like a family we discuss, analyse, disagree and defend our stories to create unique Wedding videos for our clients.</p> 

							<p>MRP captured stories, with N-Number weddings in India, including numerous destination weddings. Based in Delhi we are blessed with Hi-tec around us, and the lack of modern day entertainment gives us more time and thought to pursue what we like doing best.</p>

			 				<p>Since our inception in 2013, we aim to create audio-visual concepts for wedding. We pledge to comprehensively research our Clients goals, ethics and history to produce diverse story lines and concepts best suited to Client’s vision.</p> 
						</div>

						<br/><br/>
						<hr />
						<br/><br/>
						<div className="contact-details">
							<h2 className="sub-heading">Contact Details</h2>
							<br/>
							<p>738, Block D <br/>Kamal Pur, Burari<br/>New Delhi, Delhi - 110084</p>
							
							<a className="btn-link" href="tel:9654847105">Phone: +91 9654847105</a>
							
							<br/><br/>
							<h2>Email</h2>
							<a className="btn-link" href="mailto:mountainrootsproduction@gmail.com">mountainrootsproduction@gmail.com</a>
						</div>
						
						<br/><br/>
						<div className="team-list">
							<h2 className="sub-heading">Our Team</h2>
							<br/><br/>
							<div className="card-deck">
								{teamData.map((item, index)=>{
									return (
										<div className="card">
											<div className="card-image">
												<LazyLoad height={150}>
													<img src={item?.img} alt={item?.title} />
												</LazyLoad>
											</div>
											<div className="card-body">
												<div className="card-title">{item?.title}</div>
												{item?.position && <div className="card-desc">{item?.position}</div>}
											</div>
										</div>
									)
								})}
							</div>
						</div>
					</div>
				</div>
			<Footer />
		</>
	);
}

export default PageContact