import React from "react";
import { Link } from "react-router-dom";

const CommingSoon = (props) => {
	const { title = '', subTitle = 'Coming Soon!' } = props;
	return (
		<>
			<div className="page-soon">
				<div>
					{title && <h2>{title}</h2>}

					<h1>{subTitle}</h1>

					<Link to="/" className="btn btn-info btn-home">Go To Home</Link>
				</div>
			</div>
		</>
	);
}

export default CommingSoon