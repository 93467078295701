import React from 'react';
import { Link } from "react-router-dom";
import LazyLoad from 'react-lazyload';

const FooterData = {
	email: '',
	contact: '',
	desc: '',
	socials: [
		{
			title: 'facebook',
			url: 'https://www.facebook.com/mountainrootsproduction?mibextid=LQQJ4d',
			icon: '/images/icon-fb.png'
		},
		{
			title: 'youtube',
			url: 'https://www.youtube.com/@mountainrootsproduction7852',
			icon: '/images/icon-yt.png'
		},
		{
			title: 'instagram',
			url: 'https://www.instagram.com/mountain_rootsproduction/?igsh=MWhnNmlyaHN3NWFzMQ%3D%3D&utm_source=qr',
			icon: '/images/icon-ig.png'
		},
		// {
		// 	title: 'whatsapp',
		// 	url: 'https://wa.me/9654847105',  
		// 	icon: '/images/icon-wa.png'
		// }
	]
}

// https://wa.me/?text=urlencodedtext

const Footer = (props) => {
	return (
		<div className="mrp-footer">
			<div className="container">
				<div className="row">
					<div className="col-md-4">
						<LazyLoad>
							<Link to="/" className="mrp-logo">
								<img src="/images/mrp-logo.png" alt="mrp-logo" />
							</Link>
						</LazyLoad>
					</div>
					<div className="col-md-4">
						<div className="footer-contact">
							<a className="btn-link" id="lnkEmailAddress" href="mailto:mountainrootsproduction@gmail.com">mountainrootsproduction@gmail.com</a>
							<br/>
							<a className="btn-link" id="lnkContactAddress" href="tel:9654847105">Phone: +91 9654847105</a>
						</div>
					</div>
					<div className="col-md-4">
						<div className="footer-socials">
							{FooterData?.socials.map((item, index)=>{
								return (
									<a className="btn-social" href={item?.url} target="_blank" key={index}>
										<LazyLoad>
											<img src={item?.icon} alt={`icon ${item?.title}`}/>
										</LazyLoad>
									</a>
								)
							})}
						</div>
					</div>
				</div>
				
				<div className="footer-notes">All Images And Video Are Copyrighted. Tags: Wedding Films, Wedding Cinema, Fine-Art Cinematography, Cinematic Films, Wedding Video, Fine Editorial Wedding Photography In Mumbai, Delhi, Bangalore, Goa, Patna, Kashmir Wedding Photography, Destination Wedding Photograper, Best Wedding Photograper</div>
			</div>
		</div>
	);
}

export default Footer
