import React from 'react';
import LazyLoad from 'react-lazyload';

const Banner = (props) => {
    const { image = '' } = props;
    
    if(!image) {
        return '';
    }

    return (
        <div className="app-banner">
            <LazyLoad height="100" offset="100">
                <img src={image} alt="image-banner" />
            </LazyLoad>
        </div>
    );
}

export default Banner
