import React from "react";
import Header from '../components/header';
import Footer from '../components/footer';
import ComingSoon from '../components/coming-soon';
// css
import '../css/films.scss';

const PageFilms = (props) => {
	return (
		<>
			<Header />
			
			<ComingSoon title="Tab Films"/>

			<Footer />
		</>
	);
}

export default PageFilms