import React from "react";
import Header from '../components/header';
import Footer from '../components/footer';
import LazyLoad from 'react-lazyload';
// css
import '../css/images.scss';

const imageData = [
	{
		img: "/images/weddings/wedding-1.jpg",
		// title: "Tanmay Rawa & Tanvi"	
	},
	{
		img: "/images/weddings/wedding-2.jpg",
		// title: "Babita Rawat & Tanvi"	
	},
	{
		img: "/images/weddings/wedding-3.jpg",
		// title: "Pawan & Tanvi"
	},
	{
		img: "/images/weddings/wedding-4.jpg",
		// title: "Abhinav & Tanvi"	
	},
	{
		img: "/images/weddings/wedding-5.jpg",
		// title: "Gulshan & Tanvi"	
	},
	{
		img: "/images/weddings/wedding-6.jpg",
		// title: "Amit & Tanvi"
	},
	{
		img: "/images/weddings/wedding-7.jpg",
		// title: "Jawed & Tanvi"	
	},
	{
		img: "/images/weddings/wedding-8.jpg",
		// title: "Vaibhav & Tanvi"	
	},
	{
		img: "/images/weddings/wedding-9.jpg",
		// title: "Akshay & Tanvi"	
	},
	{
		img: "/images/weddings/wedding-10.jpg",
		// title: "Amit Rawat & Tanvi"
	},
	{
		img: "/images/weddings/wedding-11.jpg",
		// title: "Tanmay Rawa & Tanvi"	
	},
	{
		img: "/images/weddings/wedding-12.jpg",
		// title: "Babita Rawat & Tanvi"	
	},
	{
		img: "/images/weddings/wedding-13.jpg",
		// title: "Pawan & Tanvi"
	},
	{
		img: "/images/weddings/wedding-14.jpg",
		// title: "Abhinav & Tanvi"	
	},
	{
		img: "/images/weddings/wedding-15.jpg",
		// title: "Gulshan & Tanvi"	
	},
	{
		img: "/images/weddings/wedding-16.jpg",
		// title: "Amit & Tanvi"
	},
	{
		img: "/images/weddings/wedding-17.jpg",
		// title: "Jawed & Tanvi"	
	},
	{
		img: "/images/weddings/wedding-18.jpg",
		// title: "Vaibhav & Tanvi"	
	},
	{
		img: "/images/weddings/wedding-19.jpg",
		// title: "Akshay & Tanvi"	
	},
	{
		img: "/images/weddings/wedding-20.jpg",
		// title: "Amit Rawat & Tanvi"
	},
	{
		img: "/images/weddings/wedding-21.jpg",
		// title: "Tanmay Rawa & Tanvi"	
	},
	{
		img: "/images/weddings/wedding-22.jpg",
		// title: "Babita Rawat & Tanvi"	
	},
	{
		img: "/images/weddings/wedding-23.jpg",
		// title: "Pawan & Tanvi"
	},
	{
		img: "/images/weddings/wedding-24.jpg",
		// title: "Abhinav & Tanvi"	
	},
	{
		img: "/images/weddings/wedding-25.jpg",
		// title: "Gulshan & Tanvi"	
	},
	{
		img: "/images/weddings/wedding-26.jpg",
		// title: "Amit & Tanvi"
	},
	{
		img: "/images/weddings/wedding-27.jpg",
		// title: "Jawed & Tanvi"	
	},
	{
		img: "/images/weddings/wedding-28.jpg",
		// title: "Vaibhav & Tanvi"	
	},
	{
		img: "/images/weddings/wedding-29.jpg",
		// title: "Akshay & Tanvi"	
	},
	{
		img: "/images/weddings/wedding-30.jpg",
		// title: "Amit Rawat & Tanvi"
	},
	{
		img: "/images/weddings/wedding-31.jpg",
		// title: "Amit Rawat & Tanvi"
	},
	{
		img: "/images/weddings/wedding-32.jpg",
		// title: "Amit Rawat & Tanvi"
	}
]

const PageImages = (props) => {
	return (
		<>
			<Header />
			
			<div className="page-images">
				<div className="container">
					<h1 className="main-heading">Images</h1>
					<br/><br/>

					<div className="image-list">
						<div className="card-deck">
							{imageData.map((item, index)=>{
								return (
									<div className="card">
										<div className="card-image">
											<LazyLoad height={200} offset={300}>
												<img src={item?.img} alt={item?.title} />
											</LazyLoad>
										</div>
										<img className="icon-zoom" src="/images/icon-zoom.png" />
									</div>
								)
							})}
						</div>
					</div>
				</div>
			</div>

			<Footer />
		</>
	);
}

export default PageImages