import React, { useEffect, useState } from "react";
// css
// import '../css/slider.scss';

const Slider = (props) => {
	const { data } = props;

	if(!(data || []).length) {
		return '';
	}

	return (
		<>
			{/*Carousel*/}
			<div id="demo" className="carousel slide" data-bs-ride="carousel">
			  {/*Indicators/dots*/}
			  <div className="carousel-indicators">
			    	{data.map((item, index)=>{
				  		return (
				  			<button type="button" data-bs-target="#demo" data-bs-slide-to={index} className={`${index == 0 ? 'active' : ''}`}></button>
				  		)
				  	})}
			  </div>

			   {/*The slideshow/carousel*/}
			  <div className="carousel-inner">
				  	{data.map((item, index)=>{
				  		return (
				  			<div key={index} className={`carousel-item ${index == 0 ? 'active' : ''}`}>
						     	<img src={item?.img} alt={item?.title || 'banner image'} className="d-block w-100" />
						    </div>
				  		)
				  	})}
			  </div>

			  {/*Left and right controls/icons*/}
			  <button className="carousel-control-prev" type="button" data-bs-target="#demo" data-bs-slide="prev">
			    <span className="carousel-control-prev-icon"></span>
			  </button>
			  <button className="carousel-control-next" type="button" data-bs-target="#demo" data-bs-slide="next">
			    <span className="carousel-control-next-icon"></span>
			  </button>
			</div>
		</>
	);
}

export default Slider