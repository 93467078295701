import React from "react";
import Header from '../components/header';
import Footer from '../components/footer';
import ComingSoon from '../components/coming-soon';
// css
// import '../css/page404.scss';

const Page404 = (props) => {
	return (
		<>
			<Header />
			
			<ComingSoon 
				title="This URL is not correct!"
				subTitle="Page Not Found"
			/>

			<Footer />
		</>
	);
}

export default Page404