import React, { useEffect, useState } from "react";
// components
import Header from '../components/header';
import Footer from '../components/footer';
import Slider from '../components/slider';
import Banner from '../components/banner';
// css
import '../css/home.scss';

const style = {
	parallaxTop: {
		background: `url("/images/banner-home.jpg")`,
		backgroundSize: 'cover',
		minHeight: '100vh',
	},
	parallaxOther: {
		background: `url("/images/banner-experience.jpg")`,
		backgroundSize: 'cover',
		backgroundPosition: 'center',
		backgroundAttachment: 'fixed',
		minHeight: '300px',
	}
}

const sliderImages = [
	{
		img: "/images/slider/slider-1.jpg",
		title: "banner image"
	},
	{
		img: "/images/slider/slider-2.jpg",
		title: "banner image"
	},
	{
		img: "/images/slider/slider-3.jpg",
		title: "banner image"
	},
	{
		img: "/images/slider/slider-4.jpg",
		title: "banner image"
	},
	{
		img: "/images/slider/slider-5.jpg",
		title: "banner image"
	}
]

const PageHome = (props) => {
	// didmount
    // useEffect(() => {
    //     const parallax = document.getElementById("parallax-top");
	// 	// Parallax Effect for DIV 1
	// 	window.addEventListener("scroll", function () {
	// 	  let offset = window.pageYOffset;
	// 	  parallax.style.backgroundPositionY = offset * 0.7 + "px";
	// 	  // DIV 1 background will move slower than other elements on scroll.
	// 	});
    // }, []);

	return (
		<>
			<Header />

			<div className="page-home">
				<Slider data={sliderImages} />
				
				{/*<div id="parallax-top" class="parallax-item" style={style.parallaxTop}>
					<h2>Div 1</h2>
				</div>
				<div class="parallax-item" style={style.parallaxOther}>
				    <h2>Div 2</h2>
				</div>*/}

				<div className="section-main">
					<div className="container">
						<p>"Create powerful messages through the aid of technology for diverse mediums."</p>
					</div>
				</div>

				<div className="container">
					<h1 className="main-heading">Why Mountain Roots Production</h1>
				</div>
				<div className="section-banner">
					<div className="banner-heading">
						<h2>The experience</h2>
					</div>
					<Banner image="/images/banner-experience.jpg" />
				</div>

				<div className="section-content">
					<div className="container">
						<p>Having experience of over 500 luxury weddings across many states. It is built on a strong foundation of trust and satisfaction of over hundreds of couples. Based in Delhi we are blessed with Hi-tec around us, and the lack of modern day entertainment gives us more time and thought to pursue what we like doing best.</p>
					</div>
				</div>
				
				<div className="section-banner">
					<div className="banner-heading">
						<h2>The Approach</h2>
					</div>
					<Banner image="/images/banner-approach.jpg" />
				</div>

				<div className="section-content">
					<div className="container">
						<p>As a dedicated wedding photographer, We believe in preserving the magic and emotion of your special day. From the intimate glances to the joyous celebrations, our goal is to capture the essence of your love in every frame. Let's create timeless memories together that you will cherish forever.</p>
					</div>
				</div>
				
				<div className="section-banner">
					<div className="banner-heading">
						<h2>The Quality</h2>
					</div>
					<Banner image="/images/banner-quality.jpg" />
				</div>

				<div className="section-content">
					<div className="container">
						<p>Since our inception in 2013, we aim to create audio-visual concepts for wedding. We pledge to comprehensively research our Clients goals, ethics and history to produce diverse story lines and concepts best suited to Client's vision.</p>
					</div>
				</div>
			</div>

			<Footer />
		</>
	);
}

export default PageHome