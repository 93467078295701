import React from "react";
import Header from '../components/header';
import Footer from '../components/footer';
import LazyLoad from 'react-lazyload';
// css
import '../css/preweddings.scss';

const preweddingData = [
	{
		img: "/images/pre-weddings/prewedding-1.jpg",
		// title: "Tanmay Rawa & Tanvi",
		// desc: "Jodhpur, India"	
	},
	{
		img: "/images/pre-weddings/prewedding-2.jpg",
		// title: "Babita Rawat & Tanvi",
		// desc: "Jodhpur, India"	
	},
	{
		img: "/images/pre-weddings/prewedding-3.jpg",
		// title: "Pawan & Tanvi",
		// desc: "Jodhpur, India"
	},
	{
		img: "/images/pre-weddings/prewedding-4.jpg",
		// title: "Abhinav & Tanvi",
		// desc: "Jodhpur, India"	
	},
	{
		img: "/images/pre-weddings/prewedding-5.jpg",
		// title: "Gulshan & Tanvi",
		// desc: "Jodhpur, India"	
	},
	{
		img: "/images/pre-weddings/prewedding-6.jpg",
		// title: "Amit & Tanvi",
		// desc: "Jodhpur, India"
	},
	{
		img: "/images/pre-weddings/prewedding-7.jpg",
		// title: "Jawed & Tanvi",
		// desc: "Jodhpur, India"	
	},
	{
		img: "/images/pre-weddings/prewedding-8.jpg",
		// title: "Vaibhav & Tanvi",
		// desc: "Jodhpur, India"	
	},
	{
		img: "/images/pre-weddings/prewedding-9.jpg",
		// title: "Akshay & Tanvi",
		// desc: "Jodhpur, India"	
	},
	{
		img: "/images/pre-weddings/prewedding-10.jpg",
		// title: "Amit Rawat & Tanvi",
		// desc: "Jodhpur, India"
	},
	{
		img: "/images/pre-weddings/prewedding-11.jpg",
		// title: "Tanmay Rawa & Tanvi",
		// desc: "Jodhpur, India"	
	},
	{
		img: "/images/pre-weddings/prewedding-12.jpg",
		// title: "Babita Rawat & Tanvi",
		// desc: "Jodhpur, India"	
	},
	{
		img: "/images/pre-weddings/prewedding-13.jpg",
		// title: "Pawan & Tanvi",
		// desc: "Jodhpur, India"
	},
	{
		img: "/images/pre-weddings/prewedding-14.jpg",
		// title: "Abhinav & Tanvi",
		// desc: "Jodhpur, India"	
	},
	{
		img: "/images/pre-weddings/prewedding-15.jpg",
		// title: "Gulshan & Tanvi",
		// desc: "Jodhpur, India"	
	},
	{
		img: "/images/pre-weddings/prewedding-16.jpg",
		// title: "Amit & Tanvi",
		// desc: "Jodhpur, India"
	},
	{
		img: "/images/pre-weddings/prewedding-17.jpg",
		// title: "Jawed & Tanvi",
		// desc: "Jodhpur, India"	
	},
	{
		img: "/images/pre-weddings/prewedding-18.jpg",
		// title: "Vaibhav & Tanvi",
		// desc: "Jodhpur, India"	
	},
	{
		img: "/images/pre-weddings/prewedding-19.jpg",
		// title: "Akshay & Tanvi",
		// desc: "Jodhpur, India"	
	},
	{
		img: "/images/pre-weddings/prewedding-20.jpg",
		// title: "Amit Rawat & Tanvi",
		// desc: "Jodhpur, India"
	},
	{
		img: "/images/pre-weddings/prewedding-21.jpg",
		// title: "Tanmay Rawa & Tanvi",
		// desc: "Jodhpur, India"	
	},
	{
		img: "/images/pre-weddings/prewedding-22.jpg",
		// title: "Babita Rawat & Tanvi",
		// desc: "Jodhpur, India"	
	},
	{
		img: "/images/pre-weddings/prewedding-23.jpg",
		// title: "Pawan & Tanvi",
		// desc: "Jodhpur, India"
	},
	{
		img: "/images/pre-weddings/prewedding-24.jpg",
		// title: "Abhinav & Tanvi",
		// desc: "Jodhpur, India"	
	},
	{
		img: "/images/pre-weddings/prewedding-25.jpg",
		// title: "Gulshan & Tanvi",
		// desc: "Jodhpur, India"	
	},
	{
		img: "/images/pre-weddings/prewedding-26.jpg",
		// title: "Amit & Tanvi",
		// desc: "Jodhpur, India"
	},
	{
		img: "/images/pre-weddings/prewedding-27.jpg",
		// title: "Jawed & Tanvi",
		// desc: "Jodhpur, India"	
	},
	{
		img: "/images/pre-weddings/prewedding-28.jpg",
		// title: "Vaibhav & Tanvi",
		// desc: "Jodhpur, India"	
	},
	{
		img: "/images/pre-weddings/prewedding-29.jpg",
		// title: "Akshay & Tanvi",
		// desc: "Jodhpur, India"	
	},
	{
		img: "/images/pre-weddings/prewedding-30.jpg",
		// title: "Amit Rawat & Tanvi",
		// desc: "Jodhpur, India"
	},
	{
		img: "/images/pre-weddings/prewedding-31.jpg",
		// title: "Amit Rawat & Tanvi",
		// desc: "Jodhpur, India"
	},
	{
		img: "/images/pre-weddings/prewedding-32.jpg",
		// title: "Amit Rawat & Tanvi",
		// desc: "Jodhpur, India"
	}
]

const PagePreweddings = (props) => {
	return (
		<>
			<Header />
			
			<div className="page-preweddings">
				<div className="container">
					<h1 className="main-heading">Pre Wedding</h1>
					<br/><br/>

					<div className="preweddings-list">
						<div className="card-deck">
								{preweddingData.map((item, index)=>{
									return (
										<div className="card">
											<div className="card-image">
												<LazyLoad height={200} offset={300}>
													<img src={item?.img} alt={item?.title} />
												</LazyLoad>
											</div>

											{item?.title || item?.desc ? (
												<div className="card-body">
													<div className="card-title">{item?.title}</div>
													{item?.desc && <div className="card-desc">{item?.desc}</div>}
												</div>
											) : ""}
										</div>
									)
								})}
							</div>
					</div>
				</div>
			</div>

			<Footer />
		</>
	);
}

export default PagePreweddings